var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.submissions,"loading":_vm.loading,"items-per-page":200,"item-key":"submission_id","hide-default-footer":"","must-sort":""},scopedSlots:_vm._u([_vm._l((_vm.tableHeaders),function(tableHeader){return {key:("header." + (tableHeader.value)),fn:function(ref){
var header = ref.header;
return [_c('div',{key:tableHeader.value},[_vm._v(" "+_vm._s(header.text)+" "),(header.subText)?[_c('br'),_vm._v(" <"+_vm._s(header.subText)+"> ")]:_vm._e()],2)]}}}),{key:"headerCell",fn:function(ref){
var header = ref.header;
return [_c('div',[_vm._v(" "+_vm._s(header.text)+" "),(header.subText)?[_c('br'),_vm._v(" <"+_vm._s(header.subText)+"> ")]:_vm._e()],2)]}},{key:"item",fn:function(ref){
var submission = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [_c('SubmissionRow',{attrs:{"submission":submission,"expanded":isExpanded},nativeOn:{"click":function($event){return expand(!isExpanded)}}})]}},{key:"expanded-item",fn:function(ref){
var submission = ref.item;
var headers = ref.headers;
return [_c('td',{staticClass:"grey lighten-3 inset-shadow",attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[(_vm.task.type === 'segmentation')?_c('SegmentationSubmissionDetail',{attrs:{"submission":submission}}):(_vm.task.type === 'classification')?_c('ClassificationSubmissionDetail',{attrs:{"submission":submission}}):_vm._e()],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }