import { render, staticRenderFns } from "./Leaderboard.vue?vue&type=template&id=407698b5&scoped=true&"
import script from "./Leaderboard.vue?vue&type=script&lang=js&"
export * from "./Leaderboard.vue?vue&type=script&lang=js&"
import style0 from "./Leaderboard.vue?vue&type=style&index=0&id=407698b5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "407698b5",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VSpacer,VSwitch,VTab,VTabItem,VTabs,VToolbar,VToolbarItems,VToolbarTitle})
